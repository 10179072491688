/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  background-color: black;
}

a {
  color: #0563bb;
  text-decoration: none;
}

a:hover {
  color: #067ded;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0563bb;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #0678e3;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #0563bb;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9997;
  transition: all 0.5s;
  padding: 15px;
  overflow-y: auto;
}

@media (max-width: 992px) {
  #header {
    width: 300px;
    background: #fff;
    border-right: 1px solid #e6e9ec;
    left: -300px;
  }
}

@media (min-width: 992px) {
  #main {
    margin-left: 100px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.nav-menu {
  padding: 0;
  display: block;
}

.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu>ul>li {
  position: relative;
  white-space: nowrap;
}

.nav-menu a {
  display: flex;
  align-items: center;
  color: #45505b;
  padding: 10px 18px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
  border-radius: 50px;
  background: #f2f3f5;
  height: 56px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
}

.nav-menu a i {
  font-size: 20px;
}

.nav-menu a span {
  padding: 0 5px 0 7px;
  color: #45505b;
}

@media (min-width: 992px) {
  .nav-menu a {
    width: 56px;
  }

  .nav-menu a span {
    display: none;
    color: #fff;
  }
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu li:hover>a {
  color: #fff;
  background: #0563bb;
}

.nav-menu a:hover span,
.nav-menu .active span,
.nav-menu li:hover>a span {
  color: #fff;
}

.nav-menu a:hover,
.nav-menu li:hover>a {
  width: 100%;
  color: #fff;
}

.nav-menu a:hover span,
.nav-menu li:hover>a span {
  display: block;
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 28px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 0;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
}

.mobile-nav-toggle i {
  color: rgb(114, 131, 148);
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active #header {
  left: 0;
  background: black;;
}

.mobile-nav-active .mobile-nav-toggle {
  color: #fff;
  background-color: #0563bb;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: relative;
  background: black;;
  /*background: url("../img/hero-bg.jpg") top right no-repeat;*/
}

@media (min-width: 992px) {
  #hero {
    padding-left: 160px;
  }
}

/*#hero:before {
  content: "";
  background: rgba(46, 45, 43, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}*/

#hero h1 {
  margin: 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  margin-top: 100px;
}

#hero p {
  color: #ffffff;
  margin: 15px 0 0 0;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}

#hero p span {
  color: #0563bb;
  letter-spacing: 1px;
}

#hero .social-links {
  margin-top: 30px;
}

#hero .social-links a {
  font-size: 24px;
  display: inline-block;
  color: #45505b;
  line-height: 1;
  margin-right: 20px;
  transition: 0.3s;
}

#hero .social-links a:hover {
  color: #0563bb;
}

@media (max-width: 992px) {
  #hero {
    text-align: center;
  }

  #hero h1 {
    font-size: 32px;
    line-height: 36px;
  }

  #hero p {
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.background-alt {
  /*background:#f2f2f5*/
   background:black;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #0563bb;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
  color: #98a6ad;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #ffffff;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.about .content ul strong {
  margin-right: 10px;
}

.about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #0563bb;
  line-height: 0;
}

.about .content p:last-child {
  margin-bottom: 0;
  color: #ffffff;
}

.about a:link {
  text-decoration: none;
}

.about a:visited {
  text-decoration: none;
}

.about a:hover {
  text-decoration: underline;
}

.about a:active {
  text-decoration: underline;
}

/*--------------------------------------------------------------
# Facts
--------------------------------------------------------------*/
.facts .count-box {
  padding: 30px 30px 25px 30px;
  margin-top: 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #fff;
}

.facts .count-box i {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #0563bb;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.facts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #011426;
}

.facts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
/* .skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 10px 0;
  margin: 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #45505b;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #f2f3f5;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: .9s;
  background-color: #0563bb;
} */

#skills {
  padding: 50px 15px;
  text-align: center;
}

#skills h2 {
  /*color:#374054;*/
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 50px;
}

#skills ul {
  display: block;
  margin: 0 auto;
  padding: 0;
  max-width: 800px;
}

#skills li {
  display: inline-block;
  margin: 7px;
  padding: 5px 10px;
  /*color:#374054;*/
  color: rgba(255, 255, 255, 0.8);
  background: #343a40;
  list-style: none;
  cursor: default;
  font-size: 1.2em;
}

.vertical-timeline.vertical-timeline-custom-line::before {
  background: #424242;
}

.timeline-date {
  font-weight: 500;
  color: #98a6ad;
}

.projects-technologies {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.projects-technologies>* {
  margin-right: 5px;
  margin-bottom: 5px;
}

.projects-technologies>*>* {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.project-links {
  display: flex;
  flex-wrap: wrap;
}

.project-links>* {
  margin-right: 10px !important;
}

@media only screen and (max-width: 480px) {
  .ResumeProjectsBlock-links>* {
    width: 100%;
    margin: 5px 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
#contact {
  padding: 50px 15px;
  /* background: #3498db; */
  text-align: center;
}

#contact h2 {
  margin: 0 0 15px 0;
  color: #fff;
  font-weight: 500;
}

#contact-form {
  max-width: 500px;
  margin: 0 auto;
}

#contact-form input,
#contact-form textarea {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  margin-bottom: 10px;
  background: #343a40;
  /*rgb(126, 136, 144); /*#1d6fa5;*/
  color: #fff;
  transition: 0.5s ease all;
}

#contact-form input::-webkit-input-placeholder,
#contact-form textarea::-webkit-input-placeholder {
  color: #fff;
}

#contact-form input:-moz-placeholder,
#contact-form textarea:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

#contact-form input::-moz-placeholder,
#contact-form textarea::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

#contact-form input:-ms-input-placeholder,
#contact-form textarea:-ms-input-placeholder {
  color: #fff;
}

#contact-form input:focus,
#contact-form textarea:focus {
  outline: none;
  background: rgb(126, 136, 144);
  /*#16527a;*/
}

#contact-form textarea {
  height: 150px;
  resize: none;
}

#contact-form button {
  display: block;
  width: 100%;
  background: rgb(5, 99, 187);
  border-radius: 4px;
  padding: 5px 10px;
  border: none;
  color: #fff;
  font-weight: 700;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.5s ease all;
}

#contact-form button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

#contact-form button:disabled {
  background: rgb(126, 136, 144);
  cursor: not-allowed;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: black;
  color: #45505b;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

#footer h3 {
  font-size: 36px;
  font-weight: 700;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0 0 15px 0;
}

#footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}

#footer .social-links {
  margin: 0 0 40px 0;
}

#footer .social-links a {
  font-size: 24px;
  display: inline-block;
  /* background: #0563bb; */
  /* color: #fff; */
  color: #74808a;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  color: #3498db;
}

#footer .copyright {
  margin: 0 0 5px 0;
}

#footer .credits {
  font-size: 18px;
  color: "#fff";
}